<template>
    <section class="hero is-fullheight">
    <Header />
    <div class="hero-body">
      <main>
        <div class="container-body">
          <Sidebar />
          <router-view></router-view>
        </div>
      </main>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Home',
  components: {
    Header: () => import('@/components/Header'),
    Sidebar: () => import('@/components/Sidebar')
  }
}
</script>
<style scoped>
  .hero.is-fullheight .hero-body {
    padding: 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 54px);
  }

  main {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1023px) {
    .hero.is-fullheight .hero-body {
      flex-direction: column;
    }
  }
  .container-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 54px);
  }
</style>
